import { Tariff } from '@types';

export const CompaniesTariffs = {
  5: {
    tariff: Tariff.Pro,
    expiration: '2028.11.10',
  },
  144: {
    tariff: Tariff.Pro,
    expiration: '2026.10.20',
  },
  147: {
    tariff: Tariff.Pro,
    expiration: '2026.10.20',
  },
  153: {
    tariff: Tariff.Pro,
    expiration: '2026.10.01',
  },
  171: {
    tariff: Tariff.Starter,
    expiration: '2030.10.01',
  },
  176: {
    tariff: Tariff.Starter,
    expiration: '2024.10.01',
  },
} as const;