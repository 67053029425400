export enum EIntegrationKeys {
  Tinkoff = 'tinkoff',
  Stripe = 'stripe',
  GTM = 'google-tag-manager',
  GoogleA4 = 'google-analytics-4',
  GoogleUA = 'google-universal-analytics',
  YandexMetrika = 'yandex-metrika',
  Noifications = 'notifications'
}

export interface IIntegration<T = { code: string }, Key = EIntegrationKeys> {
  id?: number
  key: Key
  value: T
}
