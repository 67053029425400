import { Tariff } from '@types';

// expiration format - YYYY/MM/DD
export const CompaniesTariffs = {
  /** Cue PRO: */
  // Client id 5 до 01.03.2025 (Dvenadzat’)
  5: { tariff: Tariff.Pro, expiration: '2025/03/01' },

  // Тариф Про для клиента 2229 до 01.03.2025
  2229: { tariff: Tariff.Pro, expiration: '2025/03/01' },

  // Pro для клиента 2153 до 20 июня 2025
  2153: { tariff: Tariff.Pro, expiration: '2025/06/20' },

  // Client id 64 до 01.03.2025 (Easy Space)
  64: { tariff: Tariff.Pro, expiration: '2025/03/01' },

  // Client id 1401 до 31.03.2025 (D-shine)
  1401: { tariff: Tariff.Pro, expiration: '2025/03/31' },

  // Client id 782 до 01.09.2026 (Mechta)
  782: { tariff: Tariff.Pro, expiration: '2026/09/01' },

  // Client id 22 до 02.04.2025 (История)
  22: { tariff: Tariff.Pro, expiration: '2025/04/02' },

  // Client id 632 до 06.12.2024(Maru)
  632: { tariff: Tariff.Pro, expiration: '2024/12/06' },

  // Client id 902 до 09.03.2025 (ST Studio)
  902: { tariff: Tariff.Pro, expiration: '2025/03/09' },

  // Client id 1211 до 13.12.2024 (Sun Студия)
  // 1211: { tariff: Tariff.Pro, expiration: '2024/12/13' },
  // Студия id 1211 на 1 месяца
  // 1211: { tariff: Tariff.Pro, expiration: '2025/01/16' },
  // 1211 Sun - Тариф Pro на 1 месяц с 16.01.2025 до 16.02.2025
  1211: { tariff: Tariff.Pro, expiration: '2025/02/16' },

  // Client id 1748 до 03.02.2025 (Wood)
  1748: { tariff: Tariff.Pro, expiration: '2025/02/03' },

  // Client id 1755 до 03.02.2025 (Wood)
  1755: { tariff: Tariff.Pro, expiration: '2025/02/03' },

  // Client id 1754 до 10.06.2025 (16/17)
  1754: { tariff: Tariff.Pro, expiration: '2025/06/10' },

  // Client id 1704 до 10.06.2025 (Unity)
  1704: { tariff: Tariff.Pro, expiration: '2025/06/10' },

  // Client id 23 до 15.01.2025 (Obscura)
  23: { tariff: Tariff.Pro, expiration: '2025/01/15' },

  // Client id 2047 до 28.11.2025
  2047: { tariff: Tariff.Pro, expiration: '2025/11/28' },

  // Client id 2070 до 28.11.2025
  2070: { tariff: Tariff.Pro, expiration: '2025/11/28' },

  // Client id 2074 до 28.11.2025
  2074: { tariff: Tariff.Pro, expiration: '2025/11/28' },

  // Client id 1773 до 03.03.2025
  1773: { tariff: Tariff.Pro, expiration: '2025/03/03' },

  // Client id 2049 до 06.03.2025
  2049: { tariff: Tariff.Pro, expiration: '2025/03/06' },

  // Фабрика тариф 787 до 09.01.2025
  787: { tariff: Tariff.Pro, expiration: '2025/01/09' },

  // Студия id 781 (224point) - Cue Pro (подписка с 10.12.2024 по 10.04.2025) - 4 месяца
  781: { tariff: Tariff.Pro, expiration: '2025/04/10' },

  // Студия id 2118 до 14.03.2025
  2118: { tariff: Tariff.Pro, expiration: '2025/03/14' },

  // Студия id 1588 на 4 месяца
  1588: { tariff: Tariff.Pro, expiration: '2025/04/14' },

  // Фабрика тариф PRO 1 месяц
  1251: { tariff: Tariff.Pro, expiration: '2025/03/07' },

  // Студия id 1294 тариф PRO до 24 февраля 2025
  1294: { tariff: Tariff.Pro, expiration: '2025/02/24' },

  // Client id 25 до 15.03.2025 (Kova)
  25: { tariff: Tariff.Pro, expiration: '2025/03/15' },

  // Клиент 31, фотостудия f52 - Тариф Pro - 1 месяц - с 17 января по 17 февраля
  31: { tariff: Tariff.Pro, expiration: '2025/02/17' },

  // Client id 2057 - тариф про на 1 год
  2057: { tariff: Tariff.Pro, expiration: '2026/02/10' },

  // Client id 656 до 10.06.2025
  656: { tariff: Tariff.Pro, expiration: '2025/06/10' },

  // Client id 311 до 15.03.2025
  311: { tariff: Tariff.Pro, expiration: '2025/03/15' },

  // 2206 - Pro на 2 месяца - 05/02/2025
  2206: { tariff: Tariff.Pro, expiration: '2025/04/05' },

  // 2251 - Pro на 3 недели, для тестирования
  2251: { tariff: Tariff.Pro, expiration: '2025/03/05' },

  // 2163 - Тариф Pro 1 месяц
  2163: { tariff: Tariff.Pro, expiration: '2025/03/05' },

  // Client id 2098 - Тариф Pro на 1 месяц
  2098: { tariff: Tariff.Pro, expiration: '2025/03/11' },

  // 897 - Pro на 1 месяц
  897: { tariff: Tariff.Pro, expiration: '2025/03/13' },

  /** Cue Starter: */
  // Client id 1768 до 15.03.2025
  1768: { tariff: Tariff.Starter, expiration: '2025/03/15' },

  // Client id 1263 тариф Старт на 1 месяц
  1263: { tariff: Tariff.Starter, expiration: '2025/03/13' },

  // Client Id 930 нужно выдать Стартер доступ на год до 11.12.2025 для https://5floor.cue.business
  930: { tariff: Tariff.Starter, expiration: '2025/12/11' },

  // Client id 1688 - 24 месяца с 07.11.2026
  1688: { tariff: Tariff.Starter, expiration: '2026/11/07' },

  // Client id 456 до 03.02.2025
  456: { tariff: Tariff.Starter, expiration: '2025/02/03' },

  // Client id 960 до 03.02.2025
  960: { tariff: Tariff.Starter, expiration: '2025/02/03' },

  // Client id 1447 до 03.02.2025
  1447: { tariff: Tariff.Starter, expiration: '2025/02/03' },

  // Client id 1819 до 03.02.2025
  1819: { tariff: Tariff.Starter, expiration: '2025/02/03' },

  // Client id 1606 до 07.03.2025
  1606: { tariff: Tariff.Starter, expiration: '2025/03/07' },

  // Client id 1264 до 07.03.2025
  1264: { tariff: Tariff.Starter, expiration: '2025/03/07' },

  // Client id 1808 до 07.03.2025
  1808: { tariff: Tariff.Starter, expiration: '2025/03/07' },

  // Client id 2058 до 10.06.2025
  2058: { tariff: Tariff.Starter, expiration: '2025/06/10' },

  // Client id 988 до 10.03.2025
  988: { tariff: Tariff.Starter, expiration: '2025/03/10' },

  // Client id 1961 до 09.05.2025
  1961: { tariff: Tariff.Starter, expiration: '2025/05/09' },

  // Client id 1036 до 10.12.2025
  1036: { tariff: Tariff.Starter, expiration: '2025/12/10' },

  // Client id 2116 - старт продлить до 13 марта
  2116: { tariff: Tariff.Starter, expiration: '2025/03/13' },

  // Client id 765 до 11.03.2025
  765: { tariff: Tariff.Starter, expiration: '2025/03/11' },

  // Client id 770 до 01.01.2026
  770: { tariff: Tariff.Starter, expiration: '2026/01/01' },

  // для клиента 2045 нужно включить тариф Старт до 11 января 2025 года
  2045: { tariff: Tariff.Starter, expiration: '2025/01/11' },

  // 2125 - тариф стартер на 1 месяц с 03 февраля
  2125: { tariff: Tariff.Starter, expiration: '2025/03/03' },

  // 2147 нужно включить тариф Старт ещё месяц сверху
  2147: { tariff: Tariff.Starter, expiration: '2025/03/06' },

  // Client id 2060 до 09.01.2025
  2060: { tariff: Tariff.Starter, expiration: '2025/01/09' },

  // Client id 1010 до 09.01.2025
  1010: { tariff: Tariff.Starter, expiration: '2025/01/09' },

  // Client id 2056 до 04.03.2025
  2056: { tariff: Tariff.Starter, expiration: '2025/03/04' },

  // Client id 1777 до 10.04.2025
  1777: { tariff: Tariff.Starter, expiration: '2025/04/10' },

  // Client leika id 928 до 10.03.2025
  928: { tariff: Tariff.Starter, expiration: '2025/03/10' },

  // Client id 39 Студия Зверева Стрит - Cue Starter 1 месяц
  39: { tariff: Tariff.Starter, expiration: '2025/03/10' },

  2260: { tariff: Tariff.Starter, expiration: '2025/03/01' },

  // 2208 - тариф старт на месяц до 05/03/2025
  2208: { tariff: Tariff.Starter, expiration: '2025/03/05' },

  // 2168 - Start 6 месяцев
  2168: { tariff: Tariff.Starter, expiration: '2025/08/06' },

  // 2299 Тариф Старт на 1 год до 9 января 2026
  2299: { tariff: Tariff.Starter, expiration: '2026/01/09' },

  // 1747 - Старт 2 месяца
  1747: { tariff: Tariff.Starter, expiration: '2025/04/07' },

  // 691 до 11 марта 2025
  691: { tariff: Tariff.Pro, expiration: '2025/03/11' },

  // 992 до 11 марта 2025, тариф Старт
  992: { tariff: Tariff.Starter, expiration: '2025/03/11' },

  // 1981 Старт до 14 ферваля 2026
  1981: { tariff: Tariff.Starter, expiration: '2026/02/14' },

  // Client id 1100 - Тариф старт на 1 месяц
  1100: { tariff: Tariff.Starter, expiration: '2025/03/12' },

  // Client id 1162 - Тариф Start 1 месяц
  1162: { tariff: Tariff.Starter, expiration: '2025/03/12' },

  // Client id 2269 - Тариф Start 1 месяц
  2269: { tariff: Tariff.Starter, expiration: '2025/03/14' },

  /** Trial: */
  // Client id 2263 до 20 февраля 2025
  2263: { tariff: Tariff.Trial, expiration: '2025/02/20' },

  /** Тестовые профили: */
  // Client id 8
  8: { tariff: Tariff.Pro, expiration: '2028/01/01' },

  // Client id 34
  34: { tariff: Tariff.Pro, expiration: '2028/01/01' },

  // Client id 1823
  1823: { tariff: Tariff.Pro, expiration: '2028/01/01' },
} as const;
