import type {
  IService,
  ICompany,
  ITimeslot,
  ITimeslotsRequestsFilter,
  IClientOrder,
  ICoupon,
  // EIntegrationKeys
} from '@types';

type FetchArgumentTypes = Parameters<typeof $fetch>;

const fetchDefaultOptions = {
  onResponseError(context) {
    /** Игнорирование ошибок с неправильным именем клиента */
    if ([404].includes(context.response.status)) return;
    /** Игнорирование ошибок с неправильным именем клиента */
    if (context.request.includes('/clients/get-id/')) return;
    logError(context);
  },
  onRequestError(context) {
    logError(context);
  }
} satisfies FetchArgumentTypes[1];

function cueFetch<T>(url: string, opts: FetchArgumentTypes[1] = {}) {
  return $fetch<T>(`${useRuntimeConfig().BACKEND_URL || useRuntimeConfig().public.BACKEND_URL}/v1/${url}`, { ...fetchDefaultOptions, ...opts });
}

const api = {
  clients: {
    getId: (alias: string) => cueFetch<{ id?: string }>(`clients/get-id/${alias}`),
    get: (id: string) => cueFetch<ICompany>(`clients/get/${id}/subclient`),
    // integration: (id: string, integration: EIntegrationKeys) => cueFetch(`clients/${id}/paymentAccount/${integration}`),
  },
  services: {
    get: (id: string) => cueFetch<IService[]>(`services/${id}/small-subclient`, { query: { limit: 100 } }),
    getService: (companyId: string, serviceId: number) => cueFetch<IService>(`services/${companyId}/${serviceId}`)
  },
  slots: {
    all: (query: ITimeslotsRequestsFilter = {}, signal: AbortSignal) => cueFetch<ITimeslot[]>(`timeslot/all`, { query, signal }),
  },
  orders: {
    get: (id: number) => cueFetch<IClientOrder>(`orders/get/${id}`),
    create: (order: IClientOrder) => cueFetch<ITimeslot[]>(`orders/add`, { method: 'POST', body: order }),
    //
  },
  coupons: {
    check: (data: IClientOrder) => cueFetch<ICoupon>(`coupons/check`, { method: 'POST', body: data }),
  }
};

export default api;