import payload_plugin_ZruSkkLBMJ from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_r8aG7jUvug from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YJxXc5ONxd from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mRfdlYG6iS from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RnTsKTGDHN from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_sass@1._90eff673cdfcbe9793fee03e4e92925b/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_SqsBsvaOLL from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bWMBm6HT1u from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7dqLFbGyDE from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jCgiUIaz51 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SJO324ESlt from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/cue-subclient/.nuxt/components.plugin.mjs";
import prefetch_client_IAsT0CPBPC from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4._531bdee6a74d0b3ffe4310f623af9b00/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_edWAHTFnip from "/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_7fyXSD4NKv from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_14f79b278ded115d66297c1ae897df30/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_UNFanjZdfX from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.0_jiti@2.4.2__magicast@0.3.5_ro_6aead669bb20faf7755fc124e9998b85/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_ydwsnrsC7h from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.0_jiti@2.4.2__magicast@0.3.5_ro_6aead669bb20faf7755fc124e9998b85/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_MpqKPwjR6D from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.0_jiti@2.4.2__magicast@0.3.5_ro_6aead669bb20faf7755fc124e9998b85/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/app/apps/cue-subclient/.nuxt/unocss.mjs";
import analytics_SVnwK7EMrr from "/app/apps/cue-subclient/plugins/analytics.ts";
import error_handler_kEP5FliEXj from "/app/apps/cue-subclient/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/app/apps/cue-subclient/plugins/sentry.ts";
import ssg_detect_O86k4pBAZo from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.0_jiti@2.4.2__magicast@0.3.5_ro_6aead669bb20faf7755fc124e9998b85/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_ZruSkkLBMJ,
  revive_payload_client_r8aG7jUvug,
  unhead_YJxXc5ONxd,
  router_mRfdlYG6iS,
  _0_siteConfig_RnTsKTGDHN,
  payload_client_SqsBsvaOLL,
  navigation_repaint_client_bWMBm6HT1u,
  check_outdated_build_client_7dqLFbGyDE,
  chunk_reload_client_jCgiUIaz51,
  plugin_vue3_SJO324ESlt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IAsT0CPBPC,
  plugin_client_edWAHTFnip,
  plugin_7fyXSD4NKv,
  switch_locale_path_ssr_UNFanjZdfX,
  route_locale_detect_ydwsnrsC7h,
  i18n_MpqKPwjR6D,
  unocss_MzCDxu9LMj,
  analytics_SVnwK7EMrr,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE,
  ssg_detect_O86k4pBAZo
]