import { addDays } from 'date-fns';
import { ICompany, Tariff } from '@types';
import { CompaniesTariffs } from '@db/tariffs';
import { CompaniesTariffs as CompaniesTariffsProd } from '@db/tariffs.prod';

function isTrialExpired(created: Date) {
  const expiration = addDays(created, 14);
  const expired = expiration < new Date();
  return { expired, expiration };
}

function isTariffExpired(date?: Date) {
  return date && (date < new Date());
}

function getExpiredStatus(tariff?: string) {
  switch (tariff) {
    case Tariff.Pro: return Tariff.Expired;
    case Tariff.Starter: return Tariff.StarterExpired;
    default: return Tariff.TrialExpired;
  }
}

export function getTariff(c: ICompany, env: string, hasPaymentIntegrations?: boolean): { tariff?: Tariff; expiration?: Date } {
  const info = env === 'production' ? CompaniesTariffsProd[c.id] : CompaniesTariffs[c.id];
  if (info) {
    if (info.tariff === Tariff.Pro) {
      const expiration = info.expiration && new Date(info.expiration);
      if (!isTariffExpired(expiration)) return { tariff: info.tariff, expiration };
    }

    if (info.tariff === Tariff.Starter) {
      const expiration = info.expiration && new Date(info.expiration);
      if (!isTariffExpired(expiration)) return { tariff: info.tariff, expiration };
    }

    if (info.tariff === Tariff.Trial) {
      const expiration = info.expiration && new Date(info.expiration);
      if (!isTariffExpired(expiration)) return { tariff: info.tariff, expiration };
    }
  }

  const { expired, expiration } = isTrialExpired(c.created);
  if (!expired) return { tariff: Tariff.Trial, expiration };

  if (hasPaymentIntegrations) return { tariff: Tariff.Free };

  return { tariff: getExpiredStatus(info?.tariff) };
}

export function hasCompanyPro(c: ICompany) {
  return c?._tariff === Tariff.Pro;
}

export function hasCompanyAccessToPro(c: ICompany) {
  return [Tariff.Pro, Tariff.Trial].includes(c?._tariff);
}

export function hasCompanyStarter(c: ICompany) {
  return [Tariff.Starter].includes(c?._tariff);
}

export function hasCompanyAccessWithoutPro(c: ICompany) {
  return [Tariff.Free, Tariff.Trial].includes(c?._tariff);
}

export function hasCompanyAccessToPlatform(c: ICompany) {
  return ![Tariff.Expired, Tariff.TrialExpired, Tariff.StarterExpired].includes(c?._tariff);
}
