import type { ServiceAmountTypes, IService } from './IService';
import type { IAddress } from './IAddress';
import type { IUser } from './IUser';
import type { ITarget } from './ITarget';
import type { WeekDay } from './IDate';

export interface IOrderPrice {
  servicePrice: number
  price: number
}

export interface IOrderData extends IOrderPrice {
  dateStart?: Date,
  dateEnd?: Date,
  duration?: number,
}

export enum OrderPaymentMethods {
  cash = 'cash',
  terminal = 'terminal',
  paymentAccount = 'paymentAccount',
}

export enum OrderPaymentTypes {
  prepayment = 'prepayment',
  additional = 'additional',
  surcharge = 'surcharge',
}

export enum OrderStatuses {
  created = 'created',
  confirmed = 'confirmed',
  processed = 'processed',
  noAnswer = 'noAnswer',
  canceled = 'canceled',
  inprocess = 'inprocess',
  didNotCome = 'didNotCome',
}

export enum OrderStatusesOther {
  completed = 'completed',
  technicalReservation = 'technicalReservation'
}


export const OrderStatusesAll = { ...OrderStatusesOther, ...OrderStatuses }

export enum OrderPaymentStatuses {
  notPaid = 'notPaid',
  paid = 'paid',
  onlinePaid = 'onlinePaid',
  prepayment = 'prepayment',
  // cancel = 'cancel',
  withoutPayment = 'withoutPayment',
  return = 'return',
}

export interface IOrderPayment {
  amount: number
  currency?: string
  type: OrderPaymentTypes
  method?: OrderPaymentMethods
  format?: string
  comment?: string
  providerPaymentURL?: string
  providerPaymentStatusComment?: string
  providerPaymentStatusCode?: string
  providerPaymentStatus?: string
  providerPaymentId?: string
  providerPaymentErrorCode?: string
  provider?: string
  status?: string
}

export interface IOrderService {
  id: number
  count: number
  seatsAmount: number
  duration: number
  price?: number
  service: Omit<Partial<IService>, 'id'> & { id: string }
}

export interface IOrder {
  id?: number,
  orderSource: OrderSources;
  address: Omit<Partial<IAddress>, 'id'> & { id: string },
  created?: Date,
  isCompleted?: boolean,
  isTechnicalReservation?: boolean
  service: IOrderService
  comment?: string
  price?: number
  additionalServices: IOrderService[]
  orderServices: (IOrderService & { additionalServices: IOrderService[], weekDays: WeekDay[] })[]
  status: OrderStatuses
  discount: number
  discountType: ServiceAmountTypes
  allDates: (Date | undefined)[]
  dateTimeStart?: Date
  dateTimeEnd?: Date
  prepayment: Pick<IOrderPayment, 'amount' | 'method'>
  surcharge: Pick<IOrderPayment, 'amount' | 'method'>
  additionalPayments: IOrderPayment[]
  payments: IOrderPayment[]
  paymentStatus?: OrderPaymentStatuses
  clientId?: number
  user?: IUser
  currency: string
  customer?: IUser
}

export type OrderFromApi = Omit<IOrder, 'additionalServices' | 'additionalPayments' | 'prepayment' | 'surcharge' | 'allDates' | 'additionalServices' | 'service'>
export type OrderToApi = Omit<OrderFromApi, 'id' | 'price' | 'created' | 'address'> & {
  address?: { id: number | string }
}

export interface IClientOrderServiceAdditional {
  additionalType?: string,
  paymentTypes?: string,
  service: IService,
  duration?: number,
  count: number,
  price?: number
}

export interface IClientOrderService {
  id?: number,
  target?: Pick<ITarget, 'id'>,
  type?: string,
  seatsAmount: number,
  count: number,
  duration: number,
  service: IService,
  additionalServices: IClientOrderServiceAdditional[],
  freeAdditionalServices: IClientOrderServiceAdditional[],
  weekDays: string[]
}

export interface IClientOrder {
  id?: number
  dateTimeStart?: Date,
  dateTimeEnd?: Date,
  datetimezone?: string
  comment: string,
  price: number
  orderServices: IClientOrderService[],
  address?: Pick<IAddress, 'id' | 'timezone' | 'isOnline'>,
  payments: IOrderPayment[],
  discount?: number
  discountType?: 'amount' | 'percent'
  // customer?: InferType<typeof customerSchema>
  couponCode?: string;
  clientId?: number
  orderSource?: 'subclient'
  createFirstPayment?: boolean
  createFirstPaymentInBank?: boolean
  coupon?: Record<PropertyKey, any>
}

export enum OrderSources {
  Platform = 'platform',
  Subclient = 'subclient',
}
