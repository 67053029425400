import { EIntegrationKeys, type IIntegration } from '@types';
import VueYandex from 'vue3-yandex-metrika';

export function useMetrics() {
  const { initialize: initializeGtag, gtag } = useGtag();
  const nuxtApp = useNuxtApp();
  const router = useRouter();

  const metrics = {
    [EIntegrationKeys.GTM]: false,
    [EIntegrationKeys.YandexMetrika]: false,
  };

  function init(integrations: IIntegration[]) {
    integrations.forEach(i => {
      switch (i.key) {
        case EIntegrationKeys.GTM:
          initializeGtag(i.value.code);
          metrics[EIntegrationKeys.GTM] = true;
          return
        case EIntegrationKeys.YandexMetrika:
          nuxtApp.vueApp.use(VueYandex, {
            id: i.value.code,
            env: getEnvironment(),
            router,
          });
          metrics[EIntegrationKeys.YandexMetrika] = true;
          return
        default:
          return;
      }
    });
  }

  function addGoogleMetricEvent(command: Parameters<typeof gtag>[0], ...args: unknown) {
    if (!metrics[EIntegrationKeys.GTM]) return;
    try {
      gtag(command, ...args);
    } catch (e) {
      console.error('gtag event', e);
    }
  }

  return {
    init,
    addGoogleMetricEvent,
  };
}
