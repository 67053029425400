<script setup lang="ts">
import InlineSvg from 'vue-inline-svg';
import SkeletonCircle from '@ui/components/skeleton/SkeletonCircle.vue';

withDefaults(defineProps<{
  type?: string
  name?: string;
  loading?: boolean;
}>(), {
  type: 'svg',
  loading: false,
});

const isPlatform = useRuntimeConfig().public.PLATFORM;
</script>

<template lang="pug">
SkeletonCircle(v-if="loading")
InlineSvg.flex-shrink-0(v-else-if="name" :src="`${isPlatform ? '/new' : ''}/${type}/${name}.svg`")
</template>