<script setup lang="ts">
import { usePageTitle } from '@sub/utils/usePageSettings';
import PageError404 from '@sub/components/PageError404.vue';

usePageTitle('404');
</script>

<template lang="pug">
NuxtLayout(name="error")
  PageError404.h-100p
</template>
