import type { ITarget } from './ITarget';
import type { IAddress } from './IAddress';
import type { IFile } from './IFiles';
import type { WeekDay } from './IDate';

export enum ServiceTypes {
	Rent = 'rent',
	Individual = 'individual',
	GroupService = 'groupService',
	Additional = 'additional'
}

export enum ServiceFormats {
	time = 'time',
	timeAndPlace = 'timeAndPlace',
	without = 'without'
}

export enum ServicePrepayment {
	without = 'without',
	main = 'main',
	mainAndAdditional = 'mainAndAdditional',
}

export enum ServiceAmountTypes {
	percent = 'percent',
	amount = 'amount'
}

export interface IPriceSettingParamSmall {
	amount: string | number
	amountType: ServiceAmountTypes
}

export interface IPriceSettingParam extends IPriceSettingParamSmall {
	weekDays: string[],
	timeStart: number,
	timeEnd: number,
	durationHour: string | number,
	durationMinutes: string | number,
	amountPeople: string | number,
	isDisabled: boolean,
	sortPosition?: number
}

export interface IServicePrepaymentParams {
	weekDays: WeekDay[],
	timeStart: string,
	timeEnd: string,
	amount: number | string,
	amountType: ServiceAmountTypes,
	sortPosition?: number
}

export interface IServiceAdditionalService extends IService {
    id: number,
    isRequire: boolean,
    customerCanView: boolean
}

export enum ServicePriceSettingsParamFields {
	weekDays = 'weekDays',
	timeStart = 'timeStart',
	timeEnd = 'timeEnd',
	durationHour = 'durationHour',
	durationMinutes = 'durationMinutes',
	amountPeople = 'amountPeople',
	amount = 'amount',
}

export interface IServicePriceSettingsParam {
	weekDays: WeekDay[],
	timeStart: number,
	timeEnd: number,
	durationHour: string | number,
	durationMinutes: string | number,
	amountPeople:string | number,
	amount:string | number,
	amountPeopleOld?:string|number,
	isDisabled:boolean,
}

export enum ServicePriceSettingsActions {
	Const = 'const',
	Temporarily = 'temporarily',
	From = 'from'
}

export interface IServicePriceSettings {
	id:number,
	target?: ITarget,
	unimportantTarget:boolean,
	action: ServicePriceSettingsActions,
	dateStart?: Date,
	dateEnd?: Date,
	parameters: IServicePriceSettingsParam[],
	sortPosition?: number
}

export enum ServiceBlockBookingTypes {
	m = 'min',
	h = 'hour',
}

export enum ServiceSeatsPaymentTypes {
	place = 'place',
	time = 'time'
}

export enum ServiceAdditionalTypes {
	requisite = 'requisite',
	process = 'process',
}

export enum ServicePaymentTypes {
	time = 'time',
	piece = 'piece'
}

export interface IService {
	id: number | string,
	title: string,
	description?: string,
	price: string | number,
	priceSettings: IServicePriceSettings[],
	addresses?: IAddress[],
	type: ServiceTypes,
	additionalType: ServiceAdditionalTypes,
	files?: IFile[],
	format: ServiceFormats,
	minTimeLength: string | number,
	timeLength: string | number,
	breakIsEnable: boolean,
	break: string | number,
	blockBookingIsEnable: boolean,
	blockBookingTime: string | number,
	blockBookingType: ServiceBlockBookingTypes,
	seats: string | number,
	includedSeats: string | number,
	additionalSeatsIsEnable: boolean,
	additionalSeats: string | number,
	additionalSeatsPaymentType: ServiceSeatsPaymentTypes,
	additionalSeatsAmount: string | number,
	availableBookingHorizon?: number;
	targets?: ITarget[],
	amountPeopleMin: string | number,
	amountPeopleMax: string | number,
	paymentType: ServicePaymentTypes,
	paymentAmount: string | number,
	duration: string | number,
	parentServices?: Array<{id: number}>,
	customerCanView: boolean,
	isEnable: boolean,
	isRequire: boolean,
	isValid: boolean,
	clientId: string | number,
	sortPosition?: number,
	additionalServices: IServiceAdditionalService[] 
	prepayment: ServicePrepayment,
	prepaymentAmount: string | number,
	prepaymentType: ServiceAmountTypes,
	prepaymentParams?: IServicePrepaymentParams[];
	startTimeOffset?: number;
}

export type IServiceAdditionalFields = Pick<IService, (
	'id' |
	'type' |
	'title' |
	'description' |
	'additionalType' |
	'paymentType' |
	'paymentAmount' |
	'price' |
	'duration'
)>

export interface IServiceSmall extends Pick<IService, (
	'id' | 
	'title' |
	'type' |
	'isEnable' |
	'isValid'
)> {
	sortPosition: number
}