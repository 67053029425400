import { merge } from 'es-toolkit/compat';

export type TimeslotSettings = {
  hoverBackground?: string;
  activeColor?: string;
  activeBackground?: string;
  showPrice?: boolean;
};

type CompanySettings = {
  showTimezone?: boolean;
  showPriceSettings?: boolean;
  showServiceDuration?: boolean;
  showMinServicePrice?: boolean;
  timeslot: TimeslotSettings,
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type CustomCompanySettings = Optional<CompanySettings, 'timeslot'>;

const vorontsovskieBaniSettings = {
  showTimezone: false,
  showPriceSettings: false,
  showServiceDuration: false,
  timeslot: {
    hoverBackground: '#eef7ff',
    activeColor: '#ffffff',
    activeBackground: '#3870ff',
  },
} satisfies CustomCompanySettings;

const mechtaSettings = {
  timeslot: {
    showPrice: false,
  },
} satisfies CustomCompanySettings;

const mafiaVipSettings = {
  showPriceSettings: false,
  showMinServicePrice: false,
} satisfies CustomCompanySettings;

const studio1884Settings = {
  timeslot: {
    showPrice: false,
  },
} satisfies CustomCompanySettings;

const defaultSettings: CompanySettings = {
  showTimezone: true,
  showPriceSettings: true,
  showServiceDuration: true,
  showMinServicePrice: true,
  timeslot: {
    hoverBackground: 'unset',
    activeColor: '#3870FF',
    activeBackground: '#ECF6FF',
    showPrice: true,
  },
} satisfies CompanySettings;

function getCustomCompanySettings(clientId?: number) {
  switch (clientId) {
    case 1761:
      return vorontsovskieBaniSettings;
    case 782:
      return mechtaSettings;
    case 2040:
      return mafiaVipSettings;
    case 1336:
      return studio1884Settings;
    default:
      return {};
  }
}

export function getCompanySettings(clientId?: number): CompanySettings {
  const custom = getCustomCompanySettings(clientId);
  return merge(defaultSettings, custom);
}