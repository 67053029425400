import { defineStore } from 'pinia';
import { getImageUrl } from '../utils/images';
import { get3DomainName } from '../utils/domains';
import { placeholderImage } from '@ui/components/image/imageSettings';
import api from '../api/api';
import { type ICompany, type IFile, type IIntegration, type IService, ServiceTypes } from '@types';
import { useMetrics } from '@sub/utils/useMetrics';
import { getLogoFile, getPhotos } from '@utils/company';
import { getCompanySettings } from '@sub/utils/getCompanySettings';
import { getTariff } from '@utils/tariff';

export function getLogoUrl(company: ICompany) {
  return getImageUrl(getLogoFile(company)?.fullPath);
}

export function getCompanyImages(company: ICompany) {
  return (company.addresses || []).reduce<IFile[]>((res, a) => {
    res.push(...a.files);
    return res;
  }, getPhotos(company)).map(f => getImageUrl(f.fullPath));
}

function separateServices(services: IService[]) {
  const additional = services.filter(service => service.type === ServiceTypes.Additional);
  return {
    additional,
    main: filterValidMainServices(services.filter(service => service.type !== ServiceTypes.Additional))
      .map((service) => {
        service.additionalServices = service.additionalEnableServices
          // ?.filter(({ isEnable }) => isEnable)
          .filter(({ childrenId: id }) => additional.some(s => s.id === id))
          .map(aes => {
            const additionalService = additional.find(({ id }) => id === aes.childrenId) || {};
            return {
              ...additionalService,
              isEnable: aes.isEnable,
              isRequire: aes.isRequire,
              customerCanView: aes.customerCanView,
            };
          })
          .filter((el) => {
            const { isValid, customerCanView, isRequire } = el;
            return (isValid && (customerCanView || isRequire));
          });
        delete service.additionalEnableServices;
        return service;
        }
      )
  };
}

function filterValidMainServices(services: IService[]) {
  return services
    .filter(s => s.isValid && (s.customerCanView || s.isRequire))
    .sort((a, b) => a.sortPosition - b.sortPosition);
}

interface SeparatedServices {
  main: IService[];
  additional: IService[];
}

function getDefaultServices() {
  return { main: [], additional: [] };
}

export const useCompanyStore = defineStore('company', () => {
  const companyInfo = reactive({
    id: '',
    alias: '',
    settings: getCompanySettings(),
  });
  const integrations = ref<IIntegration[]>([]);
  const company = ref<ICompany>({} as ICompany);
  const services = ref<SeparatedServices>(getDefaultServices());

  const metrics = useMetrics();

  async function getCompanyIdByAlias(alias: string = get3DomainName()) {
    if (companyInfo.alias !== alias) {
      const data = await api.clients.getId(alias).catch(() => {
        company.value = { id: 'error' } as ICompany;
        throw showError({
          statusCode: 404,
        });
      });
      if (data?.id) {
        companyInfo.id = data.id;
        companyInfo.alias = alias;
      }
    }
    companyInfo.settings = getCompanySettings(companyInfo.id);
    return companyInfo.id;
  }

  async function getCompanyByAlias(alias: string = get3DomainName()) {
    const id = await getCompanyIdByAlias(alias);
    if (!id) return;
    return fetchCompany(id)
  }

  function applyIntegrations() {
    metrics.init(integrations.value);
  }


  const hasTinkoffIntegration = ref<boolean>();
  const tinkoff = ref();
  function checkTinkoffIntegration(data: ICompany) {
    tinkoff.value = data.merchantData;
    hasTinkoffIntegration.value = !!tinkoff.value;
  }

  const hasStripeIntegration = ref<boolean>();
  function checkStripeIntegration(data: ICompany) {
    hasStripeIntegration.value = !!data.stripeAccount?.isActive;
  }

  async function fetchCompany(id: string) {
    if (company.value.id !== id) {
      company.value = {} as ICompany;
      integrations.value = [];
      placeholderImage.value = '';

      const data = await api.clients.get(id);
      if (data) {
        checkTinkoffIntegration(data);
        checkStripeIntegration(data);
        const { tariff, expiration } = getTariff(data, getEnvironment(), hasTinkoffIntegration.value || hasStripeIntegration.value);
        data._tariff = tariff;
        data._tariff_expiration = expiration;

        data.logoUrl = getLogoUrl(data);

        company.value = data;
        placeholderImage.value = data.logoUrl;

        integrations.value = data.clientIntegrations || [];
        applyIntegrations();
      }
    }

    return company.value;
  }

  async function fetchServices(id: string) {
    if (!services.value.main.length) {
      services.value = getDefaultServices();
      const data = await api.services.get(id);
      if (data) {
        services.value = separateServices(data);
      }
    }

    return services.value;
  }

  return {
    company,
    companyInfo,
    services,
    integrations,
    metrics,
    getCompanyIdByAlias,
    fetchCompany,
    fetchServices,
    getCompanyByAlias,
    applyIntegrations,
    hasTinkoffIntegration,
    tinkoff,
    // hasStripeIntegration,
  };
}, {
  persist: {
    storage: piniaPluginPersistedstate.cookies(),
    pick: ['companyInfo.id', 'companyInfo.alias'],
  },
});