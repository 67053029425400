export const Tariff = {
  Pro: 'pro',
  Expired: 'expired',
  Starter: 'starter',
  StarterExpired: 'starter_expired',
  Free: 'free',
  Trial: 'trial',
  TrialExpired: 'trial_expired',
} as const;

export type Tariff = typeof Tariff[keyof typeof Tariff];
